// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-privacy-policy-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/de/privacy-policy.js" /* webpackChunkName: "component---src-pages-de-privacy-policy-js" */),
  "component---src-pages-de-sla-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/de/sla.js" /* webpackChunkName: "component---src-pages-de-sla-js" */),
  "component---src-pages-de-wsa-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/de/wsa.js" /* webpackChunkName: "component---src-pages-de-wsa-js" */),
  "component---src-pages-downloads-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-sla-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/en/sla.js" /* webpackChunkName: "component---src-pages-en-sla-js" */),
  "component---src-pages-en-wsa-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/en/wsa.js" /* webpackChunkName: "component---src-pages-en-wsa-js" */),
  "component---src-pages-index-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-download-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/page-download.js" /* webpackChunkName: "component---src-pages-page-download-js" */),
  "component---src-pages-pricing-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-security-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-story-js": () => import("/Users/timoreichen/Documents/web/woanna-website/src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

